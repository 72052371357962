import React, { useContext } from 'react'
import dayjs from 'dayjs/esm/index.js'

import { expandOfferObject, expandProductUrlWithFilters, quantityInPieces } from '../helpers'

import ProducerLogo from '../common/ProducerLogo'
import ProductImage from '../common/ProductImage'

import useBootstrap from '../hooks/useBootstrap'

import Title from './Title'
import Quantity from './Quantity'
import Price from './Price'
import ProductInfo from './ProductInfo'
import Offers from './Offers'
import I18n from '../../src/i18n.js.erb'
import EmailConfirmationInfo from './EmailConfirmationInfo'

import { searchContext } from './SearchContextProvider'
import { FavoriteIcon, FavoriteIconRemove, FavoriteIconDisabled } from './Icons'
import { PRODUCT_TYPES } from '../../src/product_types'

const orderByPriceAndAvailability = offers => {
  const sorted = offers.sort((a, b) => {
    try {
      if (a.in_stock && !b.in_stock) return -1
      if (!a.in_stock && b.in_stock) return 1

      if (a.in_stock && b.in_stock) return a.price_per_quantity - b.price_per_quantity

      if (a.available_starting_at_ts !== b.available_starting_at_ts) {
        return a.available_starting_at_ts - b.available_starting_at_ts
      }

      return a.price_per_quantity - b.price_per_quantity
    } catch (e) {
      return 0
    }
  })

  return sorted
}

const isMatchingFilters = (hit, offer, filters) => {
  const quantity = quantityInPieces(hit, offer, filters.quantity, filters.quantity_unit)

  if(filters.location && !filters.location.includes(offer.location)) return false
  if(filters.in_stock && !offer.in_stock) return false
  if(filters.trust_service && !offer.support_trust_service) return false
  if(quantity && quantity > offer.quantity) return false
  if(quantity && quantity < offer.min_quantity) return false
  if(quantity && offer.quantity_step && quantity % offer.quantity_step !== 0) return false
  if(filters.min_rating && filters.min_rating > offer.vendor_rating) return false
  if(filters.price && filters.price < offer.price_per_quantity) return false

  return true
}

const getPriceRange = (hit, offer, filters) => {
  const quantity = quantityInPieces(hit, offer, filters.quantity, filters.quantity_unit)
  let priceRange = null

  if(quantity) {
    priceRange = offer.price_ranges.find(priceRange => {
      return priceRange.quantity_from <= quantity && priceRange.quantity_to >= quantity
    })
  }

  if(priceRange) return [priceRange, true]

  priceRange = offer.price_ranges[0]

  for(const pr of offer.price_ranges) {
    if (pr.price_per_quantity < priceRange.price_per_quantity) {
      priceRange = pr
    }
  }

  return [priceRange, offer.price_ranges.length === 1]
}

const actualOffers = (hit, offers, filters, countryCode, userId, vendorId = null) => {
  const matched = []
  const other = []

  const now = dayjs()
  const today = now.startOf('day')
  const now_ts = now.unix()

  for (const offer of offers) {
    if (!offer.price_ranges || offer.price_ranges.length === 0) continue
    if (vendorId && offer.user_id !== vendorId) continue
    if (offer.published_until_ts < now_ts) continue
    if (userId && userId !== offer.user_id && countryCode && offer.region_blacklist && offer.region_blacklist.includes(countryCode)) continue

    if (!offer.in_stock) {
      offer.available_starting_at = dayjs.unix(offer.available_starting_at_ts)
      offer.in_stock = offer.available_starting_at <= today
    }

    const [ priceRange, actualPrice ] = getPriceRange(hit, offer, filters)

    const actualOffer = {
      ...offer,
      price_per_quantity: priceRange.price_per_quantity,
      is_actual_price: actualPrice,
      priceRange
    }

    if (isMatchingFilters(hit, actualOffer, filters))
      matched.push(actualOffer)
    else
      other.push(actualOffer)
  }

  return [orderByPriceAndAvailability(matched), orderByPriceAndAvailability(other)]
}

const Card = ({ hit, productUrl, onClick, isLoggedIn = false, ...props }) => {
  const el = useBootstrap()

  if(hit.offer.price_ranges.length === 0) return null

  const offers = hit.offers.map(expandOfferObject)

  const { filters, countryCode, vendorId, userId, favoritesUuids, emailConfirmed } = useContext(searchContext)
  const [ matched, other ] = actualOffers(hit, offers, filters, countryCode, userId, vendorId)
  const [ isFavorited, setIsFavorited ] = React.useState(favoritesUuids.some((uuid) => uuid == hit?.id))
  const [ isAnimated, setIsAnimated ] = React.useState(null)
  const productUrlWithFilters = expandProductUrlWithFilters(productUrl, filters)

  let price = null
  let isActualPrice = false

  const minPriceOffer = matched[0] || other[0]

  if(minPriceOffer) {
    price = minPriceOffer.price_per_quantity
    isActualPrice = minPriceOffer.is_actual_price && minPriceOffer === matched[0]
  }

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
    setIsAnimated(true);
  }

  const navigateToProductPage = (e) => {
    if (e.target.tagName == 'a' || e.target.tagName == 'svg') return;
    onClick(e);
  }

  return <div className='card has--offers' {...props}>
    <div onClick={navigateToProductPage} className='card__product' >
      <div onClick={onClick} class="d-block d-md-none w-100">
        <Title hit={hit} productUrl={productUrlWithFilters} />
        <ProducerLogo hit={hit} width={60} height={22} />
      </div>

      <div onClick={onClick} className='card__product-image'>
        <ProductImage hit={hit} width={130} height={150} />
      </div>

      <div className='card__product-info'>
        <div className='card__product-info-title mb-0 d-none d-md-block'>
          <div className='d-flex align-items-center' >
            <Title hit={hit} productUrl={productUrlWithFilters} />
            {isLoggedIn && !isFavorited &&
              <a rel="nofollow" onClick={() => toggleFavorite()} data-turbo="true" data-method="post" href={`/${I18n.locale}/favorites?favoritable_id=${hit.id}&favoritable_type=${PRODUCT_TYPES[hit.offer_type]}`}>
                <FavoriteIcon classes={isAnimated ? 'icon-animate-pop' : ''} />
              </a>
            }

            {isLoggedIn && isFavorited &&
              <a rel="nofollow" onClick={() => toggleFavorite()} data-turbo="true" data-method="delete" href={`/${I18n.locale}/favorites?favoritable_id=${hit.id}&favoritable_type=${PRODUCT_TYPES[hit.offer_type]}`}>
                <FavoriteIconRemove classes={isAnimated ? 'icon-animate-pop' : ''} />
              </a>
            }

            {!isLoggedIn && <FavoriteIconDisabled />}
          </div>
          <div className='card__product-info-title-producer d-flex flex-gap-3' ref={el}>
            <ProducerLogo hit={hit} width={60} height={22} />
            {hit.alternative_brand_name != null && <>{hit.alternative_brand_name}</>}

            {hit?.offer_type == 'battery' && hit?.category_name != 'batteries' &&
              <span data-toggle='tooltip' data-placement='top' data-original-title={I18n.t('frontend.offers_index.hits.battery_category_tooltips.' + hit.category_name)} className='has-tooltip'>{I18n.t('frontend.general.categories.' + hit.category_name)}</span>
            }
          </div>
        </div>

        <div onClick={onClick} className='card__product-info-details-container rounded p-3 mt-1'>
          <ProductInfo hit={hit} />
        </div>
      </div>

      <div className='card__product-availability'>
        <div className='d-flex align-items-end justify-content-between h-100 flex-column'>
          {!emailConfirmed && <EmailConfirmationInfo />}
          {emailConfirmed && <Price hit={hit} price={price} isActualPrice={isActualPrice} />}
          <Quantity offers={offers} />
        </div>
      </div>
    </div>
    <Offers hit={hit} matched={matched} other={other} isLoggedIn={isLoggedIn} navigateToProductPage={onClick} />
  </div>
}

export default Card

import React from 'react'

import I18n from '@/src/i18n.js.erb'

import { formatPrice, getPricePerUnit } from '../helpers'

// TODO: I18n
const HiddenPrice = () => <div className='card__price--container'>
  <div className='card__price card__price--hidden'>
    <a href={`/${I18n.locale}/login`}>{I18n.t('frontend.offers_index.hits.login_cta.text')}</a>
  </div>
  <span dangerouslySetInnerHTML={{ __html: I18n.t('frontend.offers_index.hits.login_cta.info') }} />
</div>

const Price = ({ hit, price, isActualPrice = false }) => {
  if (!price) return <HiddenPrice />

  const precision = hit.offer_type === 'solar_module' ? 3 : 2
  let pricePerUnit = getPricePerUnit(hit, price)

  const className = pricePerUnit ? 'card__price card__price--with-unit' : 'card__price'

  return <>
    <div>
      <div className={className}>
        <div className='w-100 text-right'>
          {!isActualPrice && <span className='text-small'>{I18n.t('frontend.general.price_from')} </span>}
          <span className='total'>{formatPrice(price, precision)} <small>€</small></span>
        </div>
      </div>
      {pricePerUnit && <div className='per-unit'>
        {pricePerUnit}
      </div>
      }
    </div>
  </>
}

export default Price

import React from 'react'

import I18n from '../../src/i18n.js.erb'

import { formatPrice } from '../helpers'

const WRAPPER_CSS_CLASSES = "text-nowrap d-flex align-items-center justify-content-end flex-gap-1"

const secondaryPriceIfAvailable = (hit, offer) => {
  switch(hit.offer_type) {
    case "battery":
      if (hit.capacity > 0) { return offer.price_per_quantity / hit.capacity }
      break
    case "inverter":
      if (hit.pnom_dc > 0) { return offer.price_per_quantity / hit.pnom_dc }
      break
    case "solar_module":
      if (hit.single_power) { return offer.price_per_quantity * hit.single_power }
      break
  }
}

const OfferPricing = ({ hit, offer }) => {
  const precision = hit.offer_type === 'solar_module' ? 3 : 2
  const showPrice = !!offer.price_per_quantity
  const showStartingFromPrefix = !offer.is_actual_price
  const unitSuffix = hit.offer_type === "solar_module" ? "W" : I18n.t("frontend.general.pcs")
  const secondaryUnitSuffix = hit.offer_type === "solar_module" ? I18n.t("frontend.general.pcs") : "KW"
  const secondaryPrice = secondaryPriceIfAvailable(hit, offer)

  return <>
    <div className={WRAPPER_CSS_CLASSES}>
      { showStartingFromPrefix && <span>{I18n.t('frontend.general.price_from')}</span> }
      <span className='value'>{formatPrice(offer.price_per_quantity, precision)}</span>
      <span className='unit'>€/{unitSuffix}</span>
    </div>
    { secondaryPrice &&
      <>
        <div className={WRAPPER_CSS_CLASSES}>
          <span className='text-faded text-small'>{formatPrice(secondaryPrice, 0)}</span>
          <span className='text-faded text-small'>€/{secondaryUnitSuffix}</span>
        </div>
      </>
    }
  </>
}

export default OfferPricing

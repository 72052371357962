import React from 'react'

import I18n from '@/src/i18n.js.erb'

const EmailConfirmationInfo = () => {
  return <div className="card__email-confirmation-info">
    <div className='card__price'>
      <span className='total'>-,-- <small>€</small></span>
    </div>
    <span className="text-muted" dangerouslySetInnerHTML={{__html: I18n.t('frontend.offers_index.hits.email_confirmation_info_html')}} />
  </div>
}

export default EmailConfirmationInfo

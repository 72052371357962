import React from 'react';
import I18n from '../../src/i18n.js.erb'
import { ArrowRight } from './Icons'

const UserRegisterCTA = ({}) => {
  return <a href={`/${I18n.locale}/signup`} class="btn btn-primary btn-rounded d-inline-flex marketplace__register align-items-center flex-gap-1 p-2 px-3">
    <ArrowRight />
    <span>{I18n.t('frontend.offers_index.filters.register_cta.button')}</span>
  </a>
}

export default UserRegisterCTA;

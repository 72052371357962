import dayjs from 'dayjs/esm/index.js'
import qs from 'qs'

import I18n from '@/src/i18n.js.erb'

const RELEVANT_BATTERY_CATEGORIES = ['battery_modules', 'battery_systems', 'battery_bundles']

export const formatPrice = (price, precision) => parseFloat(price).toFixed(precision).replace('.', ',')
export const formatQuantity = (quantity, groupSeparator = ',') => quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator)

export const getMinimumPriceOffer = offers => {
  if(offers.length === 0) return null

  let minPriceOffer = offers[0]

  for(const offer of offers) {
    if(offer.min_price < minPriceOffer.min_price) minPriceOffer = offer
  }

  return minPriceOffer
}

export const getMaxQuantityOffer = offers => {
  let max = null

  for(const offer of offers) {
    if(max === null || offer.quantity > max.quantity) max = offer
  }

  return max
}

export const getPricePerUnit = (hit, price) => {
  let pricePerUnit = null

  switch(hit.categories.lvl0) {
    case 'inverters':
      if(hit.pnom_ac) {
        pricePerUnit = Math.round(price / hit.pnom_ac)
        pricePerUnit += ' €/KW'
      }
      break
    case 'batteries': {
      const subCategory = hit.categories.lvl1 && hit.categories.lvl1.split(' > ')[1]

      if(hit.capacity && RELEVANT_BATTERY_CATEGORIES.includes(subCategory)) {
        pricePerUnit = Math.round(price / hit.capacity)
        pricePerUnit += ' €/KWh'
      }
      break
    }
    default:
  }

  return pricePerUnit
}

export const kwForQuantity = (hit, quantity) => {
  if(!hit.single_power) return null

  return (hit.single_power * quantity) / 1000.0
}

export const inStock = offer => {
  if(offer.in_stock) return true

  const available_starting_at = dayjs.unix(offer.available_starting_at_ts)
  const today = dayjs().startOf('day')

  return available_starting_at <= today
}

export const buildProductUrl = (hit, categorySlugs) => {
  const categoryString = hit.categories.lvl1 || hit.categories.lvl0
  const categoryPath = categoryString.split(' > ').map(c => categorySlugs[c]).join('/')

  return '/' + I18n.locale + '/products/' + categoryPath + '/' + hit.slug
}

export const expandProductUrlWithFilters = (productUrl, filters) => {
  const queryParams = {}
  for(const filter of Object.keys(filters)) {
    if(filters[filter] === null || filters[filter] === undefined) { continue }

    switch(filter) {
      case "quantity_unit":
        queryParams["unit"] = filters[filter]
        break

      default:
        queryParams[filter] = filters[filter]
        break
    }
  }

  const queryString = qs.stringify({filter: queryParams})

  if (queryString === '') { return productUrl }

  if (productUrl.includes("?")) {
    productUrl += "&" + queryString
  } else {
    productUrl += "?" + queryString
  }

  return productUrl
}

export const expandOfferObject = offer => {
  const offerObject = {
    id: offer.id,
    user_id: offer.uid,
    offer_no: offer.no,
    stock: offer.in_stock,
    available_starting_at_ts: offer.ava_since,
    published_until_ts: offer.ava_to,
    location: offer.loc,
    support_trust_service: offer.escrow,
    min_quantity: offer.mqty,
    quantity: offer.qty,
    quantity_step: offer.qs,
    region_blacklist: offer.rbl,
    vendor_rating: offer.vr,
    vendor_ratings_count: offer.vrc,
    full_pallets_only: offer.fp,
    full_containers_only: offer.fc,
    pallet_size: offer.ps,
    container_size: offer.cs
  }

  if(offer.mkw) offerObject.min_quantity_kw = offer.mkw
  if(offer.kw) offerObject.quantity_kw = offer.kw

  offerObject.price_ranges = offer.pr.map(pr => {
    const priceRange = {
      quantity_from: pr[0],
      quantity_to: pr[1],
      price_per_quantity: pr[2]
    }

    if(pr[3]) priceRange.quantity_kw_from = pr[3]
    if(pr[4]) priceRange.quantity_kw_to = pr[4]

    return priceRange
  })

  return offerObject
}

export const quantityInPieces = (hit, offer, quantity = 0, unit = "pcs") => {
  if(unit === "pcs") return quantity
  if(unit === "kw") return Math.ceil(quantity * 1000 / hit.single_power)
  if(unit === "pallets") return offer.pallet_size ? offer.pallet_size * quantity : quantity * 36
  if(unit === "containers") return offer.container_size ? offer.container_size * quantity : quantity * 936

  return quantity
}
